<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper>
      <h2 class="main-title">Регистрация</h2>
      <hr class="u-mt--1 u-mb--1" />
      <BaseInput
        label="Введите логин"
        v-model.trim="username"
        placeholder="Введите логин"
        type="text"
        class="u-mb--1"
      />
      <BaseInput
        label="Введите пароль"
        v-model.trim="password"
        placeholder="Введите пароль"
        inputId="password"
        type="password"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click="onSubmit"> Зарегестрироваться </Button>
      <router-link :to="{ name: 'login' }" class="login-link link u-mt--2 u-mb--1">
        Авторизация
      </router-link>
    </FormWrapper>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import api from "@/core/api";

import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import BaseInput from "@/core/components/BaseInput/BaseInput.vue";
import Button from "@/core/components/Button/Button.vue";

import Logo from "@/assets/icons/logo.svg";

const userDataMock = {
  data: {
    // name: String,
    // second_name: String,
    // last_name: String,
    // address: String,
    // phone: String,
    // email: String,
    role: [
      {
        name: "base",
        sub_role: [],
      },
    ],
  },
};

export default {
  components: { Button, FormWrapper, BaseInput, Logo },
  setup() {
    const router = useRouter();
    const username = ref("");
    const password = ref("");

    const onSubmit = async () => {
      const userData = Object.assign({}, userDataMock);
      userData.login = username.value;
      userData.password = password.value;

      try {
        await api.createUser(userData);
        router.push({ name: "login" });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      username,
      password,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss" src="./Registration.scss"></style>
