<template>
  <div class="input-wrapper">
    <label :for="inputId" v-if="label">{{ label }}:</label>
    <input
      :id="inputId"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    inputId: {
      type: String,
      default: "custom-input",
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style lang="scss" scoped src="./BaseInput.scss"></style>
